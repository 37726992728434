.change-description-row {
    border: 0.5px;
    border-style: solid;
    border-color: initial;
    border-bottom-style: hidden;
}

.change-description-card {
    width: 100%;
    height: 100%;
    border-width: '1px';
    border-style: 'solid';
    border-color: '#fafafa';
    border-bottom-style: 'hidden';
}

.change-description-headercard-title {
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5;
}

.ingestion-screen-card {
    margin-bottom: 20px;
}

.ant-tag.ant-tag-checkable.ant-tag-checkable-checked.test {
    background-color: rgb(211, 210, 210);
    color: red;
    text-decoration: line-through;
    text-decoration-color: black;
}

.ant-tag.ant-tag-checkable.test {
    background-color: rgb(211, 210, 210);
}

.classification-def {
    line-height: 2;
}

.manualMaterials {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60em;
}

.productAndMaterials {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1em;
    padding-bottom: 2em;
    width: 60em;
}

.classification-confidence {
    line-height: 1.78;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
}

.classification-level-layout {
    display: flex;
    width: 600px;
    height: 600px;
}

.classification-container {
    flex: 50%;
}

.progress-bars {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 3.5;
}

.recommendation-box {
    display: flex;
    align-items: baseline;
    justify-content: baseline;
    flex-direction: row;
}

.recommendation-display {
    padding: 10px;
}

.legend-box {
    padding-left: 24px;
    padding-bottom: 16px;
    border-bottom: 0.5px solid rgb(240, 240, 240);
}

.square {
    height: 20px;
    width: 20px;
    background-color: none;
    border-radius: 5px;
}

.nav-warnings {
    width: 400px;
}

.ant-notification-notice .nav-warnings .ant-notification-notice-closable {
    margin-right: 800px;
}

.button-divs {
    width: 50px;
}

.ant-table-thead>tr>th {
    height: 1px;
    padding: 4px;
}

.alt-table-header {
    display: inline-flex;
}

.alt-header-p {
    padding-right: 20px;
    font-weight: bold;
}

.alt-header-redcompass {
    padding-left: 30px;
    padding-right: 5px;
    font-weight: bold;
}

.classification-dropdown {
    max-width: 600px;
}

.quick-select-spin {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 240px;
}

.select-highlight {
    border: 1px solid;
    border-color: #ff4d4f;
}

.form-error {
    color: #ff4d4f;
}

.panel-border {
    border-color: #f0f0f0;
    border-style: solid;
    border-width: 0.5px;
}

.panel-border-error {
    border: 1px solid #ff4d4f;
}

.panel-border-duplicate {
    border: 1px solid #1890ff;
}

.list-highlight {
    border: 1px solid;
    border-color: #ff4d4f;
}

li.ant-list-item.list-highlight.ant-list-item-no-flex {
    border-bottom: 1px solid #ff4d4f;
}

li.ant-list-item.list-highlight:last-child.ant-list-item-no-flex {
    border-bottom: 1px solid #ff4d4f;
}

th.ant-table-cell.classification-dropdown-column {
    visibility: visible;
    width: 233px;
    padding: 16px;
}

th.ant-table-cell.classification-dropdown-column+th.ant-table-cell:not(.ant-table-cell-scrollbar) {
    width: 350px;
}

.ant-select-item-group {
    font-size: 14px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.65);
}

.ant-list>.ant-spin-nested-loading>.ant-spin-container>.ant-row {
    padding-top: 12px;
}

h4.ant-typography.regulatory-impact-header {
    padding-left: 24px;
    margin-bottom: 0px;
}

span.ant-tag.ant-tag-red {
    color: black;
    background: #fd7b7f;
    border-color: #ff0308;
    font-weight: 500;
}

span.ant-tag.ant-tag-orange {
    color: black;
    background: #fad194;
    border-color: #fa8e00;
    font-weight: 500;
}

span.ant-tag.ant-tag-green {
    color: black;
    background: #befa83;
    border-color: #6fff00;
    font-weight: 500;
}

span.ant-tag.ant-tag-yellow {
    color: black;
    background: #f4f883;
    border-color: #fff700;
    font-weight: 500;
}

.tree-heading {
    font-weight: 600;
}

.bottom-border {
    border-bottom: 1px solid rgb(240, 240, 240);
}

.tree-padding {
    padding-bottom: 8px;
    padding-top: 8px;
}

.binary-child-question {
    padding-left: 24px;
}

.tree-line {
    border-left: 1px solid rgb(0, 0, 0, 0.85);
}

.tree-title {
    border-bottom: 1px solid rgb(240, 240, 240);
    margin: 8px, 8px, 8px, 0px;
}

.view-only {
    pointer-events: none;
}

.ant-table-title {
    background-color: #fbfbfb;
}

tr.ant-table-expanded-row>td,
tr.ant-table-expanded-row:hover>td {
    background: #ffffff;
}

.subtable-padding {
    padding-top: 16px;
    padding-bottom: 16px;
}

td.ant-table-cell.subtable-border.ant-table-column-sort {
    background-color: #ffffff;
}

.subtable-padding>.ant-spin-nested-loading>.ant-spin-container>.ant-table {
    border: 1px solid rgb(240, 240, 240);
}

.subtable-padding>.ant-spin-nested-loading>.ant-spin-container>.ant-table.ant-table-bordered.ant-table-empty {
    border-color: var(--border-color, rgb(240, 240, 240));
}

.table-title {
    /* padding-left: 20px; */
    display: inline-block;
}

.ant-table.ant-table-small .ant-table-thead span.ant-table-filter-column-title {
    padding-right: 0px;
}

.ant-list-grid .ant-col>.ant-list-item.directional-assessment-market {
    margin-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
}

.line-style {
    border: 0;
    background: rgb(0 0 0 / 25%);
    padding: 1px;
    width: auto;
    margin-bottom: 15px;
    margin-top: 25px
}

#pie-chart-container {
    display: flex;
    align-items: center;
}

#pie-chart {
    /* background-image: conic-gradient(#befa83 10.00%, #fd7b7f  00.00% 90.00%); */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.key {
    list-style: none;
    display: table;
    border-collapse: separate;
}

.key li {
    /*display: table-row;*/
    margin-top: 30px;
    padding-right: 10px;
}

.key * {
    display: table-cell;
}

.percent {
    color: white;
    padding: 4px 4px;
    text-shadow: 0 0 1px black;
    text-align: center;
    font-size: 10px;
}

.choice {
    padding-left: 10px;
}

.red {
    background: #fd7b7f;
}

.green {
    background: #befa83;
}

.disable-gray {
    background: lightgray
}

.ant-table-tbody>tr.ant-table-row:hover>td {
    background: #e5e5e5
}