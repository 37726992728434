.screens {
    max-width: 950px;
    margin: 16px auto;
    align-content: center;
}
.screen {
    max-width: 950px;
    margin: 24px auto;
    align-content: center;
}

.screenForm {
    max-width: 1250px;
    margin: 40px auto 0;
    align-content: center;
}

.steps-content {
    margin-top: 16px;
    border: 1px dashed #e9e9e9;
    border-radius: 6px;
    background-color: #fafafa;
    min-height: 200px;
    text-align: center;
    padding-top: 80px;
}

.steps-action {
    align-content: center;
}
.screen-product-card {
    padding: 0px;
}
.uploader {
    height: 50%;
    width: 50%;
}
.ingest-edit {
    color: blue;
    background-color: #e9e9e973;
    padding: 5px;
    margin: 10px;
    min-width: 60px;
}

.button-margin {    
    margin: 10px;
}

.attachments-container {
    display: flex;
    flex-wrap: wrap;
}
.attachments {
    padding-left: 15px;
    padding-bottom: 15px;
    flex-basis: 100%;
}

.line-style {
    border: 0;
    background: rgb(0 0 0 / 25%);
    padding: 1px;
    width: auto;
    margin-bottom: 15px;
}
.table-title-style {
    border: 2px solid black;
    margin-bottom: 1px;
}
.table-style {
    border: 2px solid black;
}

.ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background: transparent;
}

.ant-steps-item {
   flex: auto;
}

