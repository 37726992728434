@import '~antd/dist/antd.css';

.App {
    text-align: center;
    background: #fff;
}

.Whitespace {
    background: #ffffff;
}

.Layout {
    background-color: #ffffff;
}

.App-header {
    background: #d4e3f3;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.App-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background: #d4e3f3;
    text-align: center;
    height: 50px;
    padding: 10px;
}

.ant-progress-text {
    margin-left: 15px;
    margin-right: 15px;
}

.App-link {
    color: #61dafb;
}

.App-content {
    align-content: center;
    justify-content: center;
    min-height: max-content;
    margin-top: 64px;
    margin-bottom: 50px;
    top: 64px;
    bottom: 50px;
    background: #fff;
}

.ant-card-body {
    padding-bottom: 12px;
    padding-top: 12px;
    padding-left: 24px;
    padding-right: 24px;
    z-index: 0;
}

.logout-btn {
    float: right;
    font-size: 22px;
    font-family: arial, sans-serif;
    font-weight: 600;
    color: #1184ff;
    padding-right: 2vw;
    padding-left: 2vw;
    resize: both;
    align-self: flex-start;
}

.logo-style {
    width: 130px;
    height: auto;
    margin-bottom: 5px;
    cursor: pointer;
}

img.logo-style:hover {
    opacity: 0.5;
}

.logo-container {
    margin-right: 30px
}

.print-logo {
    width: 120px;
    height: 31px;
    float: left;
    font-size: 28px;
    font-family: arial, sans-serif;
    font-weight: 600;
    color: #1184ff;
    padding-right: 1.5vw;
    margin-left: 3.5vw;
    resize: both;
    align-self: center;
    cursor: pointer;
}

.cpwss-steps {
    padding-top: 1vh;
}

.notification {
    overflow: scroll;
    z-index: 0;
    resize: both;
}

.ant-upload.ant-btn {
    width: 12px;
}

.ant-upload.ant-upload-drag.ant-upload.ant-upload-drag.ant-upload.ant-upload-drag {
    height: 65%;
}

.loading-spin {
    display: flex;
    justify-content: center;
    margin: 100px;
}

.upload-screen {
    max-width: 850px;
    margin: 24px auto;
    align-content: center;
}

.CAKE-Warning {
    display: flex;
    align-content: center;
    width: 800px;
    height: 600px;
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
    font-size: 24px;
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
    font-size: 20px;
    margin-top: 20px;
}

.div-print {
    white-space: pre-wrap;
}

.material-dropdown {
    display: flex;
}

.material-dropdown-child {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    min-width: 0;
    padding-right: 10px;
    flex: 1 1 80%;
}

.material-dropdown-icon {
    font-size: 20px;
    padding-right: 10px;
}

.material-dropdown-icon-edit:hover {
    color: green;
}

.material-dropdown-icon-trash:hover {
    color: red;
}

.cake-steps-header>.ant-steps-item.ant-steps-item-active.ant-steps-item-process:hover,
.cake-steps-header>.ant-steps-item.ant-steps-item-finish:hover {
    cursor: pointer;
}

.modal-style>.ant-modal-content {
    border: black 2px solid;
}

.edit-modal-style>.ant-modal-content {
    border: red 2px solid;
}

.comment-modal-style>.ant-modal-content {
    border: #333 2px solid;
    border-radius: 10px;
}

.comment-modal-style .ant-modal-footer {
    text-align: center;
}

.comment-modal-style .modal-textarea-style {
    margin-bottom: 8px;
    display: block;
    height: 100px;
    border: 1px solid black;
}

.anticon-warning {
    padding-right: 5px;
    color: red;
    font-size: 130%;
}


.login-user-icon {
    font-size: 25px;
    color: #1184ff;
    padding-left: 2vw;
}

@media print {
    @page: first {
        margin-top: 2cm;
    }

    @page {
        margin-top: 2cm;
        margin-bottom: 2cm;
    }

    .div-margins {
        break-inside: avoid;
    }

    .textarea-print {
        display: none;
    }

    .div-print {
        display: block;
        white-space: pre-wrap;
        overflow: visible;
        break-before: page;
    }

    thead {
        display: table-header-group;
        break-inside: auto;
    }
}

.custom-table .ant-table-body {
    height: 250px;
}

.greyContinue {
    background-color: grey !important;
    opacity: 0.5;
    border-color: grey;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
    color: black !important;
}

.ant-select .ant-select-single .ant-select-show-search {
    width: 30em !important;
}

.multi_level_data_req {
    max-width: 750px;
}

/* Global CSS Classes */

.display-block {
    display: block
}

.display-none {
    display: none;
}

.display-flex {
    display: flex;
}

.align-center {
    align-items: center;
}

.text-bold {
    font-weight: bold;
}

.text-normal {
    font-weight: normal;
}

.primary-color {
    color: #1890ff;
}

.p-0 {
    padding: 0px;
}

.p-1 {
    padding: 4px
}

.p-2 {
    padding: 8px
}

.p-3 {
    padding: 12px
}

.p-4 {
    padding: 16px
}

.m-0 {
    margin: 0px
}

.m-1 {
    margin: 4px
}

.m-2 {
    margin: 8px
}

.m-3 {
    margin: 12px
}

.m-4 {
    margin: 16px
}

.ml-1 {
    margin-left: 4px;
}

.ml-2 {
    margin-left: 8px;
}

.mr-1 {
    margin-right: 4px;
}

.mr-2 {
    margin-right: 8px;
}

.mb-1 {
    margin-bottom: 4px;
}

.mb-3 {
    margin-bottom: 12px;
}

.pr-4 {
    padding-right: 16px;
}

.material-type-style {
    font-weight: normal;
}

.btn-clist-applyall {
    width: 100%;
    text-align: right;
    margin-right: 15px;
}